import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { motion } from "framer-motion";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../assets/images/about.jpg";

const Header = () => {
  const navItems = [
    { name: "Home", link: "/" },
    { name: "About", link: "/about" },
    { name: "Services", link: "/services" },
    // { name: "Projects", link: "/project" },
  ];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false); 
  const [isFixed, setIsFixed] = useState(false); 
  const [lastScrollY, setLastScrollY] = useState(0); 
  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    const handleScroll = () => {
     
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
      setLastScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 1, y: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}
      style={{
        zIndex: 1000,
        position: isFixed ? "fixed" : "relative",
        top: isFixed ? 0 : "auto", 
        width: "100%",
        mx: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AppBar
        position={isFixed ? "fixed" : "relative"}
        sx={{
          zIndex: 1000, 
          bgcolor: "rgba(255, 255, 255, 0.9)",
          backdropFilter: "blur(30px)",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "50px",
          px: 2,
          py: 1,
          width: {xs:"90%", md:"60%"},
          mx: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "all 0.3s ease", 
          ...(isFixed && {
            left: 0, 
            right: 0,
            mx: "auto",
          }),
        }}
      >
        <Toolbar
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Logo */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <a href="/">
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              />
            </a>
          </Box>

          {/* Mobile Menu Icon */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => toggleDrawer(true)}
            sx={{ display: { xs: "block", sm: "none" }, color: "#333", mr: "auto", ml:"0px" }}
          >
            <MenuIcon />
          </IconButton>

          {/* Contact Us Button for Mobile */}
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Button
              href="/contact"
              variant="contained"
              sx={{
                backgroundColor: "#407aff",
                color: "white",
                textTransform: "none",
                fontSize:'11px',
                px: 2,
                py: 0.8,
                borderRadius: "20px",
                boxShadow: "0 4px 12px rgba(64, 122, 255, 0.2)",
                "&:hover": {
                  backgroundColor: "#335fcc",
                },
              }}
            >
              Contact Us
            </Button>
          </Box>

          {/* Mobile Drawer */}
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={() => toggleDrawer(false)}
          >
            <Box
              sx={{
                width: 250,
                bgcolor: "#f5f5f5",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <IconButton
                onClick={() => toggleDrawer(false)}
                sx={{ alignSelf: "flex-end", m: 2 }}
              >
                <CloseIcon />
              </IconButton>
              <List>
                {navItems.map((navItem, idx) => (
                  <ListItem
                    button
                    key={`mobile-link-${idx}`}
                    onClick={() => toggleDrawer(false)}
                    component="a"
                    href={navItem.link}
                  >
                    <ListItemText primary={navItem.name} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>

          {/* Desktop Navigation */}
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexGrow: 1,
              justifyContent: "center",
              gap: "20px",
            }}
          >
            {navItems.map((navItem, idx) => (
              <Button
                key={`link-${idx}`}
                href={navItem.link}
                color="primary"
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "18px",
                  color: "#333",
                  transition: "color 0.3s",
                  "&:hover": {
                    color: "#f50057",
                    transform: "translateY(-2px)",
                  },
                }}
              >
                {navItem.name}
              </Button>
            ))}
          </Box>

          {/* Contact Us Button for Desktop */}
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              href="/contact"
              variant="contained"
              sx={{
                backgroundColor: "#407aff",
                color: "white",
                textTransform: "none",
                fontSize: "14px",
                borderRadius: "30px",
                boxShadow: "0 4px 12px rgba(64, 122, 255, 0.2)",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "translateY(-3px)",
                  boxShadow: "0 6px 18px rgba(64, 122, 255, 0.4)",
                  backgroundColor: "#335fcc",
                },
              }}
            >
              Contact Us
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </motion.div>
  );
};

export default Header;
