import { React, useState, useEffect } from "react";
import { Box, Button, Container, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Aboutsec1 from "../AboutUs/AboutSec1";
import Aboutsec2 from "../AboutUs/Aboutsec2";
import Servicesec1 from "../Services/servicesec1";
import Contact from "../../pages/ContactUs/Contact";
import mobile from "../../assets/images/mobile.png";
import Element2 from "../../assets/images/Element2.png";

const Homesec1 = () => {
  const navigate = useNavigate();
  const handleMoreClick = () => {
    navigate("/services");
  };
  
  const [clientSatisfaction, setClientSatisfaction] = useState(0);
  const [clientsServed, setClientsServed] = useState(0);
  
  useEffect(() => {
    const totalDuration = 1200; // Slightly longer for smoother animation
    
    const satisfactionTarget = 100;
    const servicesTarget = 8;
    
    const satisfactionStepTime = Math.floor(totalDuration / satisfactionTarget);
    const servicesStepTime = Math.floor(totalDuration / servicesTarget);
    
    const animateCounter = (setter, targetValue, stepTime) => {
      let start = 0;
      const timer = setInterval(() => {
        start += 1;
        setter(prev => Math.min(prev + 1, targetValue));
        
        if (start >= targetValue) {
          clearInterval(timer);
        }
      }, stepTime);
      
      // Cleanup function
      return () => clearInterval(timer);
    };
    
    const satisfactionTimer = animateCounter(setClientSatisfaction, satisfactionTarget, satisfactionStepTime);
    const servicesTimer = animateCounter(setClientsServed, servicesTarget, servicesStepTime);
    
    // Clean up timers on component unmount
    return () => {
      clearInterval(satisfactionTimer);
      clearInterval(servicesTimer);
    };
  }, []);
  
  return (
    <Container disableGutters maxWidth={false} sx={{ overflow: "hidden" }}>
      {/* Hero Section */}
      <Box
        component="section"
        id="hero"
        sx={{
          position: "relative",
          minHeight: { xs: "100vh", md: "100vh" },
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* Background Element */}
        <Box
          component="img"
          src={Element2}
          alt="Element Design"
          sx={{
            width: { xs: "50px", sm: "100px", md: "150px" },
            height: "auto",
            position: "absolute",
            bottom: "10%",
            right: "0",
            opacity: 1,
            zIndex: 0,
            transition: "all 0.5s ease",
          }}
        />

        {/* Main Content Container */}
        <Container maxWidth="xl">
          <Grid 
            container 
            spacing={{ xs: 4, md: 6 }} 
            alignItems="center"
            sx={{ py: { xs: 4, sm: 6, md: 8 } }}
          >
            {/* Text Content Column */}
            <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
              <Box sx={{ pr: { md: 4 } }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                    fontSize: { xs: "16px", sm: "18px", md: "20px" },
                    fontStyle: "italic",
                    color: "#a2a2a2",
                    mb: 1,
                  }}
                >
                  Tech for everyone
                </Typography>

                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: { xs: "3.5rem", sm: "5rem", md: "9rem" },
                    color: "#407aff",
                    lineHeight: 0.9,
                    my: 1,
                    transition: "font-size 0.3s ease",
                  }}
                >
                  Calebs.
                </Typography>

                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                    fontSize: { xs: "16px", sm: "20px", md: "28px" },
                    fontStyle: "italic",
                    color: "#a2a2a2",
                    mb: 4,
                  }}
                >
                  Innovation that Inspires, Solutions that Delight
                </Typography>

                {/* Stat Boxes */}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        backgroundColor: "#407aff",
                        borderRadius: "15px",
                        color: "white",
                        textAlign: "center",
                        p: 2,
                        transition: "transform 0.3s ease",
                        "&:hover": {
                          transform: "translateY(-5px)",
                          boxShadow: "0 10px 20px rgba(64, 122, 255, 0.3)",
                        },
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          fontSize: { xs: "2.5rem", sm: "3rem", md: "4rem" },
                          fontWeight: 700,
                        }}
                      >
                        {clientSatisfaction}%
                      </Typography>
                      <Typography variant="body1" sx={{ mt: -1 }}>
                        Client Satisfaction
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      sx={{
                        backgroundColor: "#407aff",
                        borderRadius: "15px",
                        color: "white",
                        textAlign: "center",
                        p: 2,
                        transition: "transform 0.3s ease",
                        "&:hover": {
                          transform: "translateY(-5px)",
                          boxShadow: "0 10px 20px rgba(64, 122, 255, 0.3)",
                        },
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          fontSize: { xs: "2.5rem", sm: "3rem", md: "4rem" },
                          fontWeight: 700,
                        }}
                      >
                        {clientsServed}
                      </Typography>
                      <Typography variant="body1" sx={{ mt: -1 }}>
                        Client Services
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* Image Column */}
            <Grid 
              item 
              xs={12} 
              md={6} 
              order={{ xs: 2, md: 2 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: { xs: "300px", sm: "400px", md: "500px" },
                  backgroundImage: `url(${mobile})`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  transform: { xs: "none", md: "rotate(8deg)" },
                  transition: "all 0.5s ease",
                  "&:hover": {
                    transform: { xs: "scale(1.05)", md: "rotate(0deg) scale(1.05)" },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* About Section */}
      <Box component="section" id="about">
        <Aboutsec1 />
        <Aboutsec2 />
      </Box>

      {/* Services Section */}
      <Box component="section" id="services">
        <Servicesec1 />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 5,
          }}
        >
          <Button
            variant="contained"
            onClick={handleMoreClick}
            sx={{
              py: { xs: 1.5, md: 2 },
              px: { xs: 3, md: 4 },
              backgroundColor: "#407aff",
              color: "white",
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: "bold",
              borderRadius: "50px",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "#305ec2",
                transform: "scale(1.05)",
                boxShadow: "0px 8px 25px rgba(0, 64, 255, 0.4)",
              },
              boxShadow: "0px 8px 15px rgba(0, 64, 255, 0.3)",
              textTransform: "none",
            }}
          >
            Explore More Services
          </Button>
        </Box>
      </Box>

      {/* Contact Section */}
      <Box component="section" id="contact" sx={{ backgroundColor: "#fff" }}>
        <Contact />
      </Box>
    </Container>
  );
};

export default Homesec1;